enum OvhProductName {
  HOSTING = 'Hosting',
  OFFICE365 = 'Office365',
  EXCHANGE = 'Exchange',
  TELECOMETHERNET = 'TelecomEthernet',
  HEADSET = 'Headset',
  TELEPHONY = 'Telephony',
  PROJECTCLOUD = 'ProjectCloud',
  SERVER = 'Server',
  IP = 'IP',
  VPS = 'VPS',
  NUTANIX = 'Nutanix',
  MANAGEDBM = 'ManagedBM',
  VMWARE = 'VmWare',
  NAS = 'NAS',
  VEEAM = 'VEEAM',
  VRACK = 'VRACK',
  LINECOMMUNICATING = 'LineCommunicating',
  BAREMETALCLOUD = 'BareMetalCloud',
  HOSTEDPRIVATECLOUD = 'HostedPrivateCloud',
  NETWORK = 'Network',
  PUBLICCLOUD = 'PublicCloud',
  SECURITYIDENTITYOPERATION = 'SecurityIdentityOperation',
  SUNRISE = 'Sunrise',
  TELECOM = 'Telecom',
  WEBCLOUD = 'Webcloud',
  HELPECENTER = 'Helpe',
  LEAF = 'Leaf',
  LIVECHAT = 'LiveChat',
  NETWORKSTATUS = 'NetworkStatus',
  STORE = 'Store',
}

export default OvhProductName;
